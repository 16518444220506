
$version: 1.017
$path: '/assets/fonts/material-icons/material-icons'

@font-face
  font-family: "Material Icons"
  font-style: normal
  font-weight: 400
  font-display: block
  src: url("#{$path}.eot?v=1.017")
  src: local("Material Icons"), local("material-icons"), url("#{$path}.woff2?v=#{$version}") format("woff2"), url("#{$path}.woff?v=#{$version}") format("woff"), url("#{$path}.ttf?v=#{$version}") format("truetype")
