
@import "base"

@import "theme"
@import "fonts/material-icons"
@import "fonts/montserrat"
@import "helper/grid"
@import "helper/cdk-overlay"
@import "helper/animations"

*
  box-sizing: border-box

html, body
  padding: 0
  margin: 0
  line-height: 1.5
  font-size: $font-size-html
  font-weight: 400
  font-family: "Montserrat", sans-serif
  font-optical-sizing: auto
  font-style: normal
  color: $color-content-1
  background-color: $color-solid-1

h1, h2, h3, h4, h5, h6
  font-weight: 600

h1, .h1,
h2, .h2,
h3, .h3
  margin-top: size(2)
  margin-bottom: size(1)
  font-weight: 700

  &:first-child
    margin-top: 0

  &:last-child
    margin-bottom: 0

h1
  font-size: rem(42)

  @media #{$medium-and-down}
    font-size: rem(32)

h2
  font-size: rem(28)

  @media #{$medium-and-down}
    font-size: rem(20)

h3
  font-size: rem(20)

  @media #{$medium-and-down}
    font-size: rem(17)

p,
ul
  color: $color-content-2
  padding: 0
  margin: 0 0 size(1)

  &:last-child
    margin-bottom: 0

  strong
    color: $color-content-1
    font-weight: 600

ul
  padding-left: size(2.5)

a
  color: $color-key
  font-weight: 600
  text-decoration: none

  &:not(.e--link-plain):not(.ed-button)
    text-decoration: underline

img
  max-width: 100%
  height: auto
