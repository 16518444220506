
// sizes
$font-size-html: 16px

// viewport
$small-screen: 600px !default
$small-screen-up: 601px !default
$medium-screen: 991px !default
$medium-screen-up: 992px !default
$large-screen-down: 1199px !default
$large-screen: 1200px !default
$large-screen-up: 1201px !default

$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default
$large-and-down: "only screen and (max-width : #{$large-screen})" !default
$large-only: "only screen and (min-width : #{$medium-screen-up}) and (max-width : #{$large-screen})" !default

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default

$small-and-down: "only screen and (max-width : #{$small-screen})" !default

// color
$color-brand: var(--e--theme-brand)
$color-brand-rgb: var(--e--theme-brand-rgb)

$color-key: var(--e--theme-key)
$color-key-rgb: var(--e--theme-key-rgb)
$color-key-contrast: var(--e--theme-key-contrast)
$color-key-contrast-rgb: var(--e--theme-key-contrast-rgb)

$color-fn-warning: var(--e--theme-fn-warning)
$color-fn-warning-rgb: var(--e--theme-fn-warning-rgb)

$color-fn-error: var(--e--theme-fn-error)
$color-fn-error-rgb: var(--e--theme-fn-error-rgb)

$color-solid-1: var(--e--theme-level-solid-1)
$color-solid-1-rgb: var(--e--theme-level-solid-1-rgb)
$color-solid-2: var(--e--theme-level-solid-2)
$color-solid-2-rgb: var(--e--theme-level-solid-2-rgb)
$color-solid-3: var(--e--theme-level-solid-3)
$color-solid-3-rgb: var(--e--theme-level-solid-3-rgb)
$color-solid-4: var(--e--theme-level-solid-4)
$color-solid-4-rgb: var(--e--theme-level-solid-4-rgb)
$color-solid-5: var(--e--theme-level-solid-5)
$color-solid-5-rgb: var(--e--theme-level-solid-5-rgb)

$color-content-1: var(--e--theme-level-content-1)
$color-content-1-rgb: var(--e--theme-level-content-1-rgb)
$color-content-2: var(--e--theme-level-content-2)
$color-content-2-rgb: var(--e--theme-level-content-2-rgb)
$color-content-3: var(--e--theme-level-content-3)
$color-content-3-rgb: var(--e--theme-level-content-3-rgb)
$color-content-4: var(--e--theme-level-content-4)
$color-content-4-rgb: var(--e--theme-level-content-4-rgb)
