
@import "base"

\:root
  @include themeColor(brand, #22c55e)
  @include themeColor(key, #008faf)
  @include themeColor(key-contrast, #fff)
  @include themeColor(fn-warning, #FFA500)
  @include themeColor(fn-error, #ff3c00)

\:root,
.e--g-theme-bright
  @include themeColor(level-solid-1, #FFF)
  @include themeColor(level-solid-2, #F2F2F2)
  @include themeColor(level-solid-3, #a2a2a2)
  @include themeColor(level-solid-4, #686868)
  @include themeColor(level-solid-5, #000)

  @include themeColor(level-content-1, #000)
  @include themeColor(level-content-2, #686868)
  @include themeColor(level-content-3, #a2a2a2)
  @include themeColor(level-content-4, #FFF)

.e--g-theme-dark
  @include themeColor(level-solid-1, #0d1117)
  @include themeColor(level-solid-2, #161b22)
  @include themeColor(level-solid-3, #545454)
  @include themeColor(level-solid-4, #F2F2F2)
  @include themeColor(level-solid-5, #FFF)

  @include themeColor(level-content-1, #FFF)
  @include themeColor(level-content-2, #848d97)
  @include themeColor(level-content-3, #606060)
  @include themeColor(level-content-4, #000)
