
@import "@angular/cdk/overlay/index"

@include overlay()

.cdk-overlay-container .mat-mdc-dialog-container
  .mdc-dialog__surface
    box-shadow: none
    border-radius: size(1)
    background-color: transparent
