
@import "base"

.container
  margin: 0 auto
  width: 1170px
  padding: 0 size(2)
  max-width: 100%

.grid
  $num-cols: 12
  $column-gap: 25px
  display: grid
  grid-template-columns: repeat($num-cols, 1fr)
  gap: var(--f-grid-gap, $column-gap)

  =cell_styles($cols)
    grid-column: span $cols

  .cell
    min-height: 1px
    +cell_styles(12)

    @for $i from 1 through $num-cols
      &.s#{$i}
        +cell_styles($i)

    @media #{$medium-and-up}
      @for $i from 1 through $num-cols
        &.m#{$i}
          +cell_styles($i)

    @media #{$large-and-up}
      @for $i from 1 through $num-cols
        &.l#{$i}
          +cell_styles($i)

    @media #{$extra-large-and-up}
      @for $i from 1 through $num-cols
        &.xl#{$i}
          +cell_styles($i)
